<template>
  <div class="btn-like" @click="onClick">
    <VueIcon
      class="like-icon"
      v-show="!!likeStatus"
      :width="getIcon.iconLiked.width"
      :height="getIcon.iconLiked.height"
      :iconColor="getIcon.iconLiked.color"
      :iconName="getIcon.iconLiked.name"
    ></VueIcon>
    <VueIcon
      v-show="!likeStatus"
      :width="getIcon.iconLike.width"
      :height="getIcon.iconLike.height"
      :iconColor="getIcon.iconLike.color"
      :iconName="getIcon.iconLike.name"
    ></VueIcon>
  </div>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { Content } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'LikeButton',
  mixins: [gtmUtils],
  components: {
    VueIcon,
  },
  // props: ['stat'],
  props: {
    contentId: {
      type: Number,
      required: true,
    },
    isFeedDetail: {
      type: Boolean,
    },
    contentName: {
      type: String,
      default: '',
    },
    status: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
    },
  },

  data() {
    return {
      likeCount: this.$props.count,
      likeStatus: this.$props.status,
    };
  },
  computed: {
    getIcon() {
      const iconLike = ICON_VARIABLES.like;

      const iconLiked = ICON_VARIABLES.liked;
      iconLike.color = '#23303d';
      iconLiked.color = '#E5472D';
      return { iconLike, iconLiked };
    },
  },
  methods: {
    onClick() {
      event.stopPropagation();
      this.likeStatus = !this.likeStatus;
      this.likeCount = this.likeStatus ? this.likeCount + 1 : this.likeCount - 1;
      this.$emit('update:count', this.likeCount);

      this.$store.dispatch('app/setOverlayFunction', false);
      Content.likeContent({ contentId: this.contentId }).then(() => {
        this.$store.dispatch('app/setOverlayFunction', true);
      });

      if (this.likeStatus) {
        this.pushDataLayerEvent('favorilere_ekle', {
          type: this.contentName,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.btn-like {
  cursor: pointer;
  .like-icon {
    width: 22px;
  }
}
</style>
