<template>
  <div class="feed-item-container" :ref="`feedItemObserver_${modelData.feedId}`">
    <BrandMediaContentBox
      class="feed-media"
      :mediaSrc="modelData.imageURL"
      :alt="modelData.title"
      @click.native="detailClick(modelData)"
      :style="marathonContentBackgroundStyle"
      :aspectRatios="propData.media.aspect.by_16_9"
      :isMarathon="!isCompetitionNotRewardShower(modelData)"
      :marathonContentStyle="marathonContentStyle"
    >
      <div class="feed-media-content">
        <VueBadge v-if="modelData.feedType === 1" :radius="20" class="overlay overlay-left">
          <LikeButton
            class="btn-feed-like"
            :status="modelData.liked"
            :count.sync="modelData.likeCount"
            :contentId="modelData.feedId"
            :contentName="modelData.title"
            @click.native="sendLikeEvent"
          />
        </VueBadge>
        <VueBadge v-if="modelData.feedType === 1" :radius="20" class="feed-category">{{
          modelData.category.categoryText
        }}</VueBadge>
      </div>
    </BrandMediaContentBox>
    <div class="feed-item-summary" v-if="isCompetitionNotRewardShower(modelData)">
      <VueText v-if="modelData.likeCount && modelData.likeCount > 0" sizeLevel="4">
        {{ $t('feedPage.body.likeCount', { count: modelData.likeCount }) }}
      </VueText>
      <VueHeadline @click.native="detailClick(modelData)" level="2">{{
        modelData.title
      }}</VueHeadline>
      <VueText
        v-if="modelData.shortDescription"
        sizeLevel="4"
        class="feed-item-content"
        @click.native="detailClick(modelData)"
        >{{
          modelData.shortDescription.length > 120
            ? modelData.shortDescription.slice(0, 120) + '...'
            : modelData.shortDescription
        }}</VueText
      >
    </div>
  </div>
</template>
<script>
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueBadge from '@/components/shared/VueBadge/VueBadge.vue';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import LikeButton from '@/components/brand/FeedbackButton/LikeButton.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesSurvey from '@/router/routes/secure/RoutesSurvey';
import RoutesPlayAndWin from '@/router/routes/secure/RoutesPlayAndWin';
import gtmUtils from '@/mixins/gtmUtils.js';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'FeedItem',
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    eventTitle: {
      type: String,
      default: '',
    },
  },
  components: {
    VueHeadline,
    VueText,
    VueBadge,
    BrandMediaContentBox,
    LikeButton,
  },
  mixins: [gtmUtils],
  data() {
    return {
      modelData: Object.assign({}, this.model),
    };
  },
  computed: {
    marathonContentBackgroundStyle() {
      return !this.isCompetitionNotRewardShower(this.modelData)
        ? {
            'background-image': `url(${require('@/assets/bg/newmarathonbg.jpg')})`,
            width: '100%',
            height: '314px',
          }
        : {};
    },
    marathonContentStyle() {
      return !this.isCompetitionNotRewardShower(this.modelData)
        ? {
            height: '314px',
          }
        : {};
    },
    propData() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
      return { media };
    },
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.initObserver();
      });
    }, 1000);
  },
  methods: {
    initObserver() {
      if (this.$refs[`feedItemObserver_${this.modelData.feedId}`]) {
        const observer = new IntersectionObserver(
          entries => {
            if (entries[0].isIntersecting) {
              this.pushDataLayerEvent('feedItem', {
                feed: this.modelData,
                scroll: document.querySelector('.main-inner-tab-bar').scrollTop > 0,
                index: this.index + 1,
                listType: this.eventTitle,
              });
              let link = '';
              if (this.modelData.feedType === 1) {
                link = this.$router.resolve({
                  name: `${RoutesSecure.FeedDetail.name}`,
                  params: { id: this.modelData.feedId },
                });
              } else if (this.modelData.feedType === 2) {
                if (this.modelData.surveyType === 2 || this.modelData.surveyType === 1) {
                  link = this.$router.resolve({
                    name: `${RoutesSurvey.TakeSurvey.name}`,
                    params: { id: this.modelData.feedId },
                  });
                } else if (this.modelData.surveyType === 3) {
                  link = this.$router.resolve({
                    name: `${RoutesPlayAndWin.PlayAndWinDetail.name}`,
                  });
                }
              }
              this.pushDataLayerEvent('ecommerce');
              this.pushDataLayerEvent('view_promotion', {
                link: this.$router.resolve(link)?.href,
                bannerId: this.modelData.feedId,
                title: this.modelData.title,
                creative:
                  this.modelData.feedType === 1
                    ? 'Content'
                    : this.modelData.surveyType === 2 || this.modelData.surveyType === 1
                    ? 'Survey'
                    : this.modelData.surveyType === 3
                    ? RoutesPlayAndWin.PlayAndWinDetail.name
                    : '',
                type: this.eventTitle,
                group: this.modelData.category?.categoryText,
              });
              observer.unobserve(entries[0].target);
            }
          },
          { threshold: [1] },
        );
        observer.observe(this.$refs[`feedItemObserver_${this.modelData.feedId}`]);
      }
    },
    async detailClick(feedItem) {
      let link = '';
      if (feedItem.feedType === 1) {
        this.$emit('contentClick');
        link = {
          name: `${RoutesSecure.FeedDetail.name}`,
          params: { id: this.modelData.feedId },
        };
      } else if (feedItem.feedType === 2) {
        if (feedItem.surveyType === 2 || feedItem.surveyType === 1) {
          link = {
            name: `${RoutesSurvey.TakeSurvey.name}`,
            params: { id: this.modelData.feedId },
          };
        }
        if (feedItem.surveyType === 3) {
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('odül_yagmuru', {
            location: 'Survey',
          });
          new StorageHelper({ id: 'PlayableInfo' }).set(feedItem);

          link = {
            path: `${
              feedItem.feedPriorty == 1
                ? feedItem.marathonRedirectUrl
                : RoutesPlayAndWin.PlayAndWinDetail.name
            }`,
          };
        }
        this.$emit('marathonEvents', true);
      } else {
        const errModalConfig = {
          isModalOpened: true,
          modalText: 'Post bulunamadı!',
          modalType: 'alert',
          alertType: 'error',
          firstButtonText: 'Tamam',
        };
        this.$store.dispatch('app/setModalConfig', errModalConfig);
      }
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', {
        link: this.$router.resolve(link)?.href,
        bannerId: this.modelData.feedId,
        title: this.modelData.title,
        creative:
          this.modelData.feedType === 1
            ? 'Content'
            : this.modelData.surveyType === 2 || this.modelData.surveyType === 1
            ? 'Survey'
            : this.modelData.surveyType === 3
            ? RoutesPlayAndWin.PlayAndWinDetail.name
            : '',
        type: this.eventTitle,
        group: this.modelData.category?.categoryText,
      });
      if (link) this.$router.push(link);
    },
    getCookie(cname) {
      var name = cname + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    sendLikeEvent() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('favorilere_ekle', {
        type: this.modelData.category.categoryText,
      });
    },
    isCompetitionNotRewardShower(data) {
      return data?.competition?.title !== 'RewardShower';
    },
  },
};
</script>
<style scoped lang="scss">
$overlay-side-space: palette-space-level(20);
$space-10: palette-space-level(10);
.feed-media {
  position: relative;
  display: flex;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  overflow: hidden;
  &-content {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;

    .overlay {
      bottom: $space-10;
      -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      font-size: palette-font-size-level(4);
      font-weight: palette-font-weight-level(3);
      &.overlay-left {
        left: $overlay-side-space;
        max-height: 34px;
        width: 34px;
        height: 34px;
        justify-content: center;
        .btn-like {
          display: flex;
          position: relative;
          top: 1px;
        }
        svg {
          height: 16px;
        }
        span {
          padding-left: palette-space-level(5);
        }
      }
      &.overlay-right {
        right: $overlay-side-space;
        float: right;
        padding: $space-10;
      }
    }
  }
}

.feed-item-container {
  .btn-feed-like {
    display: flex;
    align-items: center;
  }
  .feed-category {
    font-size: 12px;
    font-weight: 500;
    max-width: 200px;
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  }
  .feed-item-summary {
    padding: 10px 20px 0;

    h2,
    p {
      padding-bottom: 10px;
    }

    .feed-item-content {
      color: $brand-link-primary-hover-color;
      cursor: pointer;
    }
  }
}
.feedback-icons {
  display: flex;
  margin-bottom: 10px;
  .feedback-icon {
    width: auto;
    height: 14px;
    margin-right: 5px;
  }
  .feedback-count {
    font-size: 12px;
  }
}
</style>
