<template>
  <div class="modal-backdrop" v-show="isActive">
    <transition name="slide">
      <div :style="{ 'max-height': modalHeight }" class="modal-wrapper" v-if="isActive">
        <div
          class="modal-header"
          v-if="!headerWithActions && !headerWithFilter"
          :class="headerClass"
        >
          <VueText v-if="title" sizeLevel="7" weightLevel="3" color="white-100">{{
            title
          }}</VueText>
          <a @click="handleClose(false)"
            ><VueIcon
              :iconName="getIcon.cross.name"
              iconColor="#ffffff"
              :width="getIcon.cross.width"
              :height="getIcon.cross.height"
            ></VueIcon
          ></a>
        </div>
        <div class="modal-header-with-actions" v-if="headerWithActions">
          <VueText v-if="title" sizeLevel="7" weightLevel="3" color="white-100">{{
            title
          }}</VueText>

          <div class="header-buttons" @click.prevent="toggleSelect">
            <VueText sizeLevel="3" weightLevel="3" color="white-100">Allow All</VueText>

            <VueIcon
              :iconName="getIcon.circle.name"
              v-if="!isSelected"
              iconColor="#ffffff"
              class="button"
            />
            <VueIcon
              v-show="isSelected"
              :iconName="getIcon.tick.name"
              iconColor="#ffffff"
              class="button-active"
            />
          </div>
        </div>
        <div class="modal-header-with-actions" v-if="headerWithFilter">
          <VueText v-if="title" sizeLevel="7" weightLevel="3" color="white-100">{{
            title
          }}</VueText>
          <div class="header-buttons" @click.prevent="toggleSelect">
            <VueText sizeLevel="3" weightLevel="3" color="white-100">{{ filterCategory }}</VueText>

            <VueIcon
              :iconName="getIcon.caret.name"
              :iconSize="{ width: getIcon.caret.width, height: getIcon.caret.height }"
              class="button"
            />
          </div>
        </div>
        <div
          :style="{ 'max-height': modalHeight, 'padding-bottom': `${bottomPadding}px` }"
          class="modal-body"
        >
          <slot></slot>
        </div>

        <div
          class="modal-footer"
          :style="{ 'border-top': plainTextModal ? '1px solid #79838e' : '' }"
        >
          <BrandButton
            v-if="!submitFilterButton && !plainTextModal && !headerWithFilter"
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="handleSubmit"
            >Uygula</BrandButton
          >
          <VueButton
            v-if="submitFilterButton"
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="handleSubmit"
            class="filter-button"
            >Filtrele</VueButton
          >
          <BrandButton
            v-if="plainTextModal"
            :outlined="true"
            bottomFixed
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="handleSubmit"
            >Kapat</BrandButton
          >
          <VueButton
            v-if="headerWithFilter"
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="handleSubmit"
            class="filter-button"
            >Filtrele</VueButton
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';

export default {
  name: 'BrandSelectModal',
  components: { VueText, BrandButton, VueIcon, VueButton },
  directives: {
    open: {
      // directive definition
      inserted: function(el) {
        el.focus();
      },
    },
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    submitFilterButton: {
      type: Boolean,
      default: false,
    },
    plainTextModal: {
      type: Boolean,
      default: false,
    },
    headerWithActions: {
      type: Boolean,
      default: false,
    },
    headerWithFilter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    filterCategory: {
      type: String,
    },
    headerClass: {
      type: String,
    },
    bottomPadding: {
      type: Number,
      default: 80,
    },
  },
  watch: {
    showModal: {
      handler: function(val) {
        this.isActive = val;
      },
    },
    $route: {
      handler: function() {
        this.isActive = false;
        // this.handleSubmit();
      },
    },
  },
  data() {
    return {
      isActive: this.$props.showModal,
      isSelected: false,
    };
  },

  computed: {
    modalHeight() {
      return `${window.innerHeight - 90}px`;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return {
        circle: ICON_VARIABLES.circle,
        tick: ICON_VARIABLES.rememberMe,
        caret: ICON_VARIABLES.chevronDownAlt,
        cross: ICON_VARIABLES.cross,
      };
    },
  },
  methods: {
    handleSubmit(e) {
      this.$emit('click', e);
    },
    handleClose(status) {
      document.documentElement.style.overflow = 'auto';
      this.$emit('onModalClose', status);
    },
    toggleSelect(e) {
      this.$emit('headerAction', e);
      this.isSelected = !this.isSelected;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/_animations.scss';

.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 40;
  top: 0;
  left: 0;
  object-fit: contain;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.7);
  .modal-wrapper {
    position: fixed;
    bottom: 0;
    z-index: 40;
    width: 100%;

    .modal-body {
      overflow: auto;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}

.modal-header {
  align-items: center;
  justify-content: space-between;
}
.modal-header-with-actions {
  align-items: center;
  justify-content: space-between;
}
.modal-header-with-actions,
.modal-header {
  display: flex;
  padding: palette-space-level('15') palette-space-level('20');
  width: 100%;
  background-color: palette-color-level('grey', '40');
}

.header-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button,
.button-active {
  margin-left: palette-space-level('10');
}

.modal-body,
.filter-button {
  background-color: palette-color-level('white', '100');
  border-radius: 0 !important;
}

.bg-cover-blue {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('blue', '40');
}
.bg-cover-yellow {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('yellow', '30');
}
.bg-cover-red {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('fushia', '20');
}
.bg-cover-peach {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('pink', '10');
}
.bg-cover-orange {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('orange', '10');
}
</style>
