export default {
  methods: {
    contentImageSize(size) {
      this.appParentBanner = size;
    },

    calculateDimension(parentDimension, subDimension, webSize) {
      return (parentDimension * subDimension) / webSize;
    },

    setAppDimension(parentBanner, banner, webParentBanner) {
      const appDimension = {};

      appDimension.subImage = {};
      appDimension.subImage.width = this.calculateDimension(
        parentBanner.width,
        banner.widthSubImage,
        webParentBanner.width,
      );
      appDimension.subImage.height = this.calculateDimension(
        parentBanner.height,
        banner.heightSubImage,
        webParentBanner.height,
      );
      appDimension.subImage.top = this.calculateDimension(
        parentBanner.height,
        banner.topSubImage,
        webParentBanner.height,
      );
      appDimension.subImage.left = this.calculateDimension(
        parentBanner.width,
        banner.leftSubImage,
        webParentBanner.width,
      );

      appDimension.description = {};
      appDimension.description.width = this.calculateDimension(
        parentBanner.width,
        banner.widthDescription,
        webParentBanner.width,
      );
      appDimension.description.height = this.calculateDimension(
        parentBanner.height,
        banner.heightDescription,
        webParentBanner.height,
      );
      appDimension.description.top = this.calculateDimension(
        parentBanner.height,
        banner.topDescription,
        webParentBanner.height,
      );
      appDimension.description.left = this.calculateDimension(
        parentBanner.width,
        banner.leftDescription,
        webParentBanner.width,
      );

      appDimension.description.fontSize = this.calculateDimension(
        parentBanner.width,
        banner.fontSize,
        webParentBanner.width,
      );

      return appDimension;
    },
    getStyleDynamicDescription(dynamicBanner) {
      const appDescriptionDimension = this.setAppDimension(
        this.appParentBanner,
        dynamicBanner,
        this.webParentBanner,
      );

      return `top: ${appDescriptionDimension.description.top}px; left: ${
        appDescriptionDimension.description.left
      }px; width: ${appDescriptionDimension.description.width}px; height: ${
        appDescriptionDimension.description.height
      }px; font-family:${dynamicBanner.fontFamily}; font-size:${
        appDescriptionDimension.description.fontSize
      }px; font-style:${dynamicBanner.isBold ? 'bold' : 'none'}; color:${
        dynamicBanner.descriptionColor
      }; `;
    },
    getStyleDynamicSubImage(banner) {
      const appSubImageDimension = this.setAppDimension(
        this.appParentBanner,
        banner,
        this.webParentBanner,
      );

      return `top: ${appSubImageDimension.subImage.top}px; left: ${appSubImageDimension.subImage.left}px; width: ${appSubImageDimension.subImage.width}px; height: ${appSubImageDimension.subImage.height}px;`;
    },
  },
};
