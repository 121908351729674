<template>
  <div id="divFunWrapper">
    <div ref="anchorFunTop"></div>
    <BrandTab
      idField="contentTypeId"
      nameField="contentName"
      :selectedTabId="this.selectedId"
      @select="selectTab($event)"
      :tabList="contentTypes"
      :fixed="true"
    />
    <div class="fun-wrapper">
      <div v-if="selectedId === 0 || selectedId">
        <BrandContentSlider swiperId="mainSliderFun"></BrandContentSlider>
        <VueContentHolder :padding="[20, 0, 20, 20]">
          <VueText weightLevel="3" color="grey-50" sizeLevel="6">En Yeniler</VueText>
        </VueContentHolder>
        <FeedList
          :feedItems="feeds"
          :eventTitle="`Yaşam / ${selectedTabName}`"
          :infiniteLoader="getMoreFeed"
          @nextpage="getContents(true)"
        ></FeedList>
      </div>
      <BrandSelectModal
        :showModal="getFunPageModalStatus"
        :headerWithFilter="true"
        :filterCategory="this.filterCategoryName"
        :title="this.selectCategoryModalConfig.modalTitle"
        @headerAction="closeModal"
        @click="submitFilters"
      >
        <VueContentHolder :padding="['0', '20', '50']" :key="selectedCategoryIndex.length">
          <div class="filter-list">
            <VueListItem
              class="filter-list-item"
              @click="onRowSelected(item.contentTypeId, index)"
              :text="item.contentName"
              v-for="(item, index) in selectCategoryModalConfig.modalBody"
              :key="`${item.contentTypeId}${item.selected}`"
              :icon="getIcon(item.selected)"
              :contentAlignment="constants.flexAlignment.between"
            ></VueListItem>
          </div>
        </VueContentHolder>
      </BrandSelectModal>
    </div>
  </div>
</template>
<script>
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import { mapGetters } from 'vuex';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import BrandContentSlider from '@/components/brand/Slider/BrandContentSlider';
import 'swiper/dist/css/swiper.css';
import FeedList from '@/components/brand/FeedList/FeedList.vue';
import { Fun } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import { maxBy } from '@/mixins/arrayUtils.js';
import BrandTab from '@/components/brand/BrandTab/BrandTab.vue';
import StorageHelper from '@/utils/storageHelper';
import GtmHelper from '@/utils/gtmHelper';

export default {
  name: 'Fun',
  mixins: [gtmUtils],
  components: {
    BrandSelectModal,
    VueText,
    VueContentHolder,
    VueListItem,
    FeedList,
    BrandContentSlider,
    BrandTab,
  },
  computed: {
    ...mapGetters('app', ['getFunPageModalStatus', 'getSearchedText']),
    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    setSearchedText() {
      return this.getSearchedText;
    },
  },
  watch: {
    setSearchedText: {
      handler: function() {
        this.searchedText = this.getSearchedText;
        this.lastId = 0;
        this.lastIndex = 0;
        this.getContents(false, this.searchedText);
      },
    },
    selectedId: {
      handler() {
        if (this.selectedId === 0) {
          this.pushVirtualPvEvent();
        }
      },
      deep: true,
      immediate: true,
    },
    filterCategoryName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          let userDetails = new StorageHelper({
            id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
          }).get();
          let gtmHelper = new GtmHelper(userDetails);
          gtmHelper.initDataLayer(this.from, this.to);
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').page_path = 'Yaşam / ' + newVal;
        }
      },
    },
  },
  data() {
    return {
      lastIndex: 0,
      lastId: 0,
      pageSize: 10,
      getMoreFeed: true,
      filteredId: null,
      filterCategoryName: '',
      selectedId: null,
      searchedText: '',
      filteredItemsFromModal: [],
      selectCategoryModalConfig: {
        modalBody: [],
        modalTitle: 'Kategoriler',
      },
      selectedCategory: null,
      selectedTabName: '',
      selectedCategoryIndex: [],
      contentTypes: [],
      feeds: [],
      from: null,
      to: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.from = from;
      vm.to = to;
      let userDetails = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
      }).get();
      let gtmHelper = new GtmHelper(userDetails);
      gtmHelper.initDataLayer(vm.from, vm.to);
      window.dataLayer
        .slice()
        .reverse()
        .find(i => i.event === 'commercial_data').page_path = 'Yaşam / ' + 'Tüm İçerikler';
    });
  },
  created() {
    Fun.getContentParentCategories()
      .then(res => {
        this.contentTypes = res.data.Data.contentTypes;

        const firstItemId = this.contentTypes[0].contentTypeId;
        this.selectedId = firstItemId;
        this.selectedTabName = this.contentTypes[0].contentName;
        return firstItemId;
      })
      .then(firstItemId => {
        Fun.getContentSubCategories(firstItemId)
          .then(res => {
            if (res && res.data) {
              const contentTypes = res.data.Data.contentTypes;
              if (contentTypes) {
                contentTypes.forEach(item => {
                  this.selectCategoryModalConfig.modalBody.push({ ...item, selected: false });
                });
              }
            }
          })
          .then(() => {
            this.getContents(false);
            this.handleModalCategoryText();
          });
      });
  },
  methods: {
    pushVirtualPvEvent(filterName) {
      let userDetails = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
      }).get();
      let gtmHelper = new GtmHelper(userDetails);
      gtmHelper.initDataLayer(this.from, this.to);
      window.dataLayer
        .slice()
        .reverse()
        .find(i => i.event === 'commercial_data').page_path = filterName
        ? this.eventTitle + ' / ' + filterName
        : this.eventTitle;
    },
    submitFilters() {
      this.lastIndex = 0;
      this.lastId = 0;
      this.$refs.anchorFunTop.scrollIntoView();
      let filterContentName = this.filterCategoryName;
      if (
        this.selectCategoryModalConfig.modalBody &&
        Array.isArray(this.selectCategoryModalConfig.modalBody) &&
        this.selectCategoryModalConfig.modalBody.length > 0
      ) {
        let content = this.selectCategoryModalConfig.modalBody.find(x => x.selected);
        if (content) {
          filterContentName = content.contentName;
        }
      }

      this.getContents(false, filterContentName);
      this.setModalStatus(false);
    },
    setModalStatus(status) {
      this.$store.dispatch('app/setFunPageModalStatus', status);
    },
    closeModal() {
      this.setModalStatus(false);
    },
    selectTab(tabItem) {
      this.selectedId = tabItem.contentTypeId;
      this.selectedTabName = tabItem.contentName;
      this.lastIndex = 0;
      this.lastId = 0;
      let searchedText = '';
      this.$store.dispatch('app/setSearchedText', searchedText);
      this.filteredId = null;
      this.$refs.anchorFunTop.scrollIntoView();
      Fun.getContentSubCategories(this.selectedId).then(res => {
        if (res && res.data) {
          const contentTypes = res.data.Data.contentTypes;

          let modalBody = this.selectCategoryModalConfig.modalBody;

          modalBody.splice(0, modalBody.length);

          if (contentTypes) {
            contentTypes.forEach(item => {
              modalBody.push({ ...item, selected: false });
            });
          }
        }

        this.getContents(false, tabItem.contentName);
        this.handleModalCategoryText();
      });
    },
    getContents(isNextPage, filter) {
      if (filter) {
        this.pushDataLayerEvent('filterContent', { filterName: filter });
      }

      Fun.getContent(
        this.lastId,
        this.lastIndex,
        this.pageSize,
        this.filteredId || this.selectedId,
        this.searchedText,
      ).then(res => {
        const {
          Data: { contents },
        } = res.data;
        if (isNextPage) {
          if (contents.length < this.pageSize) {
            this.getMoreFeed = false;
          }
        }
        if (isNextPage) {
          this.feeds.push(...contents);
        } else {
          this.feeds = contents;
        }
        this.lastIndex = this.feeds.length;
        if (this.feeds.length) {
          this.lastId = maxBy(this.feeds, 'feedId');
        } else {
          this.lastId = 0;
        }
      });
    },
    handleModalCategoryText() {
      if (this.selectedId) {
        this.contentTypes.forEach(item => {
          if (item.contentTypeId === this.selectedId) {
            this.filterCategoryName = item.contentName;
          }
        });
      }
    },
    getIcon(isSelected) {
      if (isSelected) {
        return ICON_VARIABLES['checked'];
      } else {
        return ICON_VARIABLES['circle'];
      }
    },
    onRowSelected(id, index) {
      this.selectCategoryModalConfig.modalBody
        .filter(item => item.selected === true)
        .map(item => (item.selected = false));
      this.selectCategoryModalConfig.modalBody[index].selected = !this.selectCategoryModalConfig
        .modalBody[index].selected;
      if (this.filteredId != id) {
        this.filteredId = id;
      } else {
        this.filteredId = null;
        this.selectCategoryModalConfig.modalBody[index].selected = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.fun-wrapper {
  overflow: hidden;
  &-contents {
    overflow: auto;
  }
}

.image-slider-container {
  padding-bottom: palette-space-level('35');
  .swiper-slide {
    width: 100%;
    height: 250px;
  }
}

.filter-list {
  &-item {
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
