<template>
  <div>
    <ul class="feed-list">
      <li
        :key="`feed_${i}_${f.feedId}`"
        v-for="(f, i) in feedItems"
        class="feed-list-item"
        v-in-viewport="'-320px 0px'"
      >
        <FeedItem
          :model="f"
          :index="i"
          :eventTitle="eventTitle"
          @contentClick="contentClicked(f, i)"
          @marathonEvents="marathonEvents"
        ></FeedItem>
      </li>
      <li v-if="infiniteLoader" v-show="feedItems && feedItems.length > 0" ref="loadMore"></li>
    </ul>
  </div>
</template>

<script>
import FeedItem from '@/components/brand/FeedItem/FeedItem.vue';
import gtmUtils from '@/mixins/gtmUtils.js';
import { mapGetters } from 'vuex';

export default {
  name: 'FeedList',
  mixins: [gtmUtils],
  components: {
    FeedItem,
  },
  props: {
    eventTitle: {
      type: String,
      default: '',
    },
    feedItems: {
      type: Array,
    },
    infiniteLoader: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('app', ['getSearchedText']),
  },
  methods: {
    contentClicked(feed, index) {
      this.pushDataLayerEvent('contentClicked', {
        ...feed,
        pageName: this.eventTitle,
        index: index + 1,
      });
    },
    marathonEvents() {
      this.$emit('marathonEvents', true);
    },
  },
  mounted() {
    if (this.infiniteLoader) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            this.$emit('nextpage');
          }
        },
        { threshold: [0] },
      );

      observer.observe(this.$refs.loadMore);
    }
  },
};
</script>
<style scoped lang="scss">
.feed-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0 0 15px 0;
  }
}
</style>
