<template>
  <div class="flex-container">
    <div :class="cssClasses.mediaContentBox" :style="boxStyles" @click="goToLink">
      <component :is="'iframe'" allowfullscreen v-if="isEmbededVideo" />
      <div :class="cssClasses.imageWrapper" v-else>
        <div ref="intersectObserver"></div>
        <img
          v-if="lazyload"
          :class="imageClasses"
          ref="mediaImgLazy"
          src=""
          :alt="alt"
          :style="marathonContentStyle"
        />
        <img v-else :class="cssClasses.imageClasses" ref="mediaImg" :src="mediaSrc" :alt="alt" />
      </div>
      <slot />
    </div>
    <MarathonDetailCard v-if="isMarathon" :isCollapsed="false" :isHeader="false" />
  </div>
</template>

<script>
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import MarathonDetailCard from '@/views/pages/secure/marathon/MarathonDetailCard.vue';

export default {
  name: 'BrandMediaContentBox',
  components: {
    MarathonDetailCard,
  },
  props: {
    as: {
      type: String,
      default: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.image,
      // validator: value => variationValidators(variations, value), // TODO: @Kuli, bu prop validasyonuna bakılacak!
    },
    aspectRatios: {
      type: String,
      default: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS.by_1_1,
    },
    backgroundColor: {
      type: String,
    },
    mediaSrc: {
      type: String,
      default: '',
      required: false,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    alt: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    bannerItem: {
      type: Object,
      required: false,
    },
    isSliderContent: {
      type: Boolean,
      default: false,
    },
    isSliderClick: {
      type: Boolean,
      default: false,
    },
    isMarathon: {
      type: Boolean,
      default: false,
    },
    marathonContentStyle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      imageClasses: [this.$style['image-item']],
    };
  },
  mounted() {
    if (!this.isEmbededVideo && this.lazyload) {
      this.$refs.mediaImgLazy.addEventListener('load', () => {
        const width = this.$refs?.mediaImgLazy?.offsetWidth;
        const height = this.$refs?.mediaImgLazy?.offsetHeight;
        this.$emit('contentImageSize', { width, height });

        this.imageClasses.push(this.$style[`image-item-loaded`]);
      });
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            if (this.$refs.mediaImgLazy) {
              this.$refs.mediaImgLazy.src = this.mediaSrc;
              observer.unobserve(this.$refs.intersectObserver);
            }
          }
        },
        { threshold: [0] },
      );
      observer.observe(this.$refs.intersectObserver);
    }
  },
  methods: {
    async goToLink() {
      this.$emit('onClickSliderContent', this.bannerItem);
      const originUrl = this.convertToOriginUrl();
      const reg = /(http(s?)):\/\//;
      if (this.href) {
        if (this.href.indexOf(originUrl) !== -1) {
          let newLink = this.href.replace(originUrl, '');
          this.$router.push(newLink);
        } else {
          window.location.href = reg.test(this.href) ? this.href : `https://${this.href}`;
        }
      }
    },

    convertToOriginUrl() {
      let origin = document.location.origin;

      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      const isDevOrQa = apiBaseUrl.includes('qa') || apiBaseUrl.includes('dev');

      const appendString = 'app';
      const splitURL = origin.split('//');

      return !isDevOrQa ? splitURL[0] + '//' + appendString + '.' + splitURL[1] : origin;
    },
  },
  computed: {
    cssClasses() {
      const imageWrapper = [this.$style['image-wrapper']];
      const imageClasses = [this.$style['image-item']];
      const mediaContentBox = [this.$style['media-content-box']];
      mediaContentBox.push(this.$style[`media-content-box-${this.aspectRatios}`]);
      if (!this.lazyload && this.mediaSrc) {
        imageClasses.push(this.$style[`image-item-loaded`]);
      }

      return { mediaContentBox, imageWrapper, imageClasses };
    },
    isEmbededVideo() {
      return this.as === MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.embed;
    },
    boxStyles() {
      const boxStyleObj = {};

      if (this.backgroundColor) {
        boxStyleObj.backgroundColor = this.backgroundColor;
      }

      return boxStyleObj;
    },
  },
};
</script>

<style module lang="scss">
.flex-container {
  display: flex;
  justify-content: space-between;
}
.media-content-box {
  position: relative;
  display: flex;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  figure {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
.image-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.image-item {
  width: 100%;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  &-loaded {
    visibility: visible;
    opacity: 1;
  }
}
.media-content-box-1by1 {
  &:before {
    padding-top: 100%;
  }
}
.media-content-box-4by3 {
  &:before {
    padding-top: 75%;
  }
}
.media-content-box-16by9 {
  &:before {
    padding-top: 56.25%;
  }
}
.media-content-box-21by9 {
  &:before {
    padding-top: 42.857143%;
  }
}
</style>
