<template>
  <div v-if="contents && sliderOptions" class="slider-container">
    <swiper
      v-if="contents && sliderOptions"
      :options="sliderOptions"
      :ref="swiperId"
      @slideChangeTransitionEnd="startAutoPlayVideo()"
      @ready="startAutoPlayVideo(), pushDLEvent()"
      @slideChange="onSlideChange()"
    >
      <swiper-slide v-for="(banner, index) in contents" :key="getBannerKey(banner, index)">
        <div v-if="banner.isDynamicBanner">
          <div :style="getStyleDynamicDescription(banner)" class="banner-dynamic-content">
            {{ banner.description }}
          </div>
          <img
            v-if="banner.subImage"
            :style="getStyleDynamicSubImage(banner)"
            class="banner-dynamic-content"
            :src="banner.subImage"
            alt="pm-aktif-logo"
          />
        </div>
        <BrandVimeoPlayer
          v-if="banner.embedVideo"
          :data="banner"
          :id="banner.bannerId"
          :logger="logger"
          :hasCover="true"
          :isChangedSlider="isChangedSlider"
          @play="onVideoPlay(banner)"
          @pause="onVideoPause()"
        ></BrandVimeoPlayer>
        <div id="iframeContainer" v-else-if="banner.iFrame">
          <iframe
            id="frame"
            class="player-iframe"
            :src="banner.iFrame"
            frameborder="0"
            @load="load(banner)"
          ></iframe>
        </div>
        <BrandMediaContentBox
          v-else
          :mediaSrc="banner.imageURL"
          :aspectRatios="media.aspect.by_16_9"
          :href="banner.link"
          @onClickSliderContent="onClickSliderItem(banner)"
          @contentImageSize="contentImageSize"
        />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
import { Common, JourneyAction } from '@/services/Api/index';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import gtmUtils from '@/mixins/gtmUtils.js';
import banner from '@/mixins/banner.js';
import { ACTION_TYPE, JOURNEY_ITEM_TYPE } from '@/constants/journey.constants.js';

export default {
  name: 'BrandContentSlider',
  components: {
    BrandVimeoPlayer,
    BrandMediaContentBox,
    swiper,
    swiperSlide,
  },
  props: {
    slides: {
      type: Array,
    },
    swiperId: {
      type: String,
      default: 'mySwiper',
    },
    config: {
      type: Object,
    },
    logger: {
      type: String,
      default: '',
    },
  },
  mixins: [gtmUtils, banner],
  methods: {
    getBannerKey(banner, index) {
      return `content-${banner.bannerId}-${index}`;
    },
    load(banner) {
      this.currentBanner = banner;
      let videoPlay = () => {
        this.onIFramePlay();
      };
      window.addEventListener('blur', function() {
        videoPlay();
      });
    },
    onIframeClick() {
      this.isClicked = true;
    },
    onSlideChange() {
      this.isChangedSlider = !this.isChangedSlider;
      if (this.scrolled > 400) return;
      this.pushDLEvent();
    },
    onClickSliderItem(banner) {
      this.pushDataLayerEvent('clickContent', {
        ...banner,
        position: ++this.positionForClickEvent,
      });
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', {
        creative: 'Banner',
        bannerId: banner.bannerId,
        title: banner.title,
        link: banner.link,
      });

      this.sendClickBannerLog(banner);
    },
    sendClickBannerLog(banner) {
      JourneyAction.journeyActionLog({
        journeyItemId: banner.bannerId,
        journeyItemType: JOURNEY_ITEM_TYPE.MAIN_BANNER,
        actionType: ACTION_TYPE.CLICK,
      }).catch(error => {
        console.error('Error logging banner click:', error);
      });
    },
    onChangedSliderItem(banner) {
      this.pushDataLayerEvent('changedContent', [
        {
          id: banner.bannerId,
          name: banner.title,
          creative: banner.imageURL,
          position: banner.position,
        },
      ]);
      if (!this.viewed_promotions.includes(banner.bannerId)) {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('view_promotion', {
          creative: 'Banner',
          bannerId: banner.bannerId,
          title: banner.title,
          link: banner.link,
        });
        this.viewed_promotions.push(banner.bannerId);
      }
      JourneyAction.journeyActionLog({
        journeyItemId: banner.bannerId,
        journeyItemType: JOURNEY_ITEM_TYPE.MAIN_BANNER,
        actionType: ACTION_TYPE.VIEW,
      }).catch(error => {
        console.error('Error logging banner view:', error);
      });
    },
    pushDLEvent() {
      if (this.contents.length) {
        let sw = this.$refs[this.swiperId];
        let index = sw.swiper.activeIndex;
        this.onChangedSliderItem(this.contents[index]);
      }
    },
    onVideoPause() {
      this.$refs[this.swiperId].swiper.pagination.el.style.display = '';
    },
    onVideoPlay(banner) {
      if (!this.$refs[this.swiperId]?.swiper) return;
      else {
        this.$refs[this.swiperId].swiper.pagination.el.style.display = 'none';
        this.$refs[this.swiperId].swiper.autoplay.stop();
        this.onClickSliderItem(banner);
      }
    },
    onIFramePlay() {
      if (!this.$refs[this.swiperId]?.swiper) return;
      else {
        this.$refs[this.swiperId].swiper.pagination.el.style.display = 'none';
        this.$refs[this.swiperId].swiper.autoplay.stop();
      }
    },
    startAutoPlayVideo() {
      if (this.autoPlay) {
        let sw = this.$refs[this.swiperId];
        let activeIndex = sw.swiper.activeIndex;
        let slides = sw.swiper.slides;
        if (
          slides &&
          slides.length > 0 &&
          this.contents &&
          this.contents.length > 0 &&
          this.contents[activeIndex].embedVideo
        ) {
          let vidCover = slides[activeIndex].querySelector('.iframe-cover');
          vidCover.click();
        }
      }
    },
    setSwiperOptions(options) {
      if (options) {
        if (options.bannerSliderType) {
          if (options.bannerSliderType.type === 3 && options.period > 0) {
            this.sliderOptions.autoplay.delay = options.period * 2000;
            this.autoPlay = false;
          } else {
            delete this.sliderOptions.autoplay;
            this.autoPlay = options.autoplay;
          }

          if (options.bannerSliderType.type === 1) {
            this.contents = [this.contents[0]];
            this.sliderOptions.resistance = true;
            this.sliderOptions.resistanceRatio = 0;
          }
        }
      }
    },
    initContentSlider() {
      if (this.contents?.length > 0) {
        setTimeout(() => {
          if (!this.viewed_promotions.includes(this.contents[0]?.bannerId)) {
            this.pushDataLayerEvent('ecommerce');
            this.pushDataLayerEvent('view_promotion', {
              creative: 'Banner',
              bannerId: this.contents[0]?.bannerId,
              title: this.contents[0]?.title,
              link: this.contents[0]?.link,
            });
            this.viewed_promotions.push(this.contents[0]?.bannerId);
          }
        }, 2000);
      }
    },
  },
  mounted() {
    const elMain = document.querySelector('.main-inner-tab-bar');

    if (elMain) {
      elMain.addEventListener('scroll', () => {
        this.scrolled = elMain.scrollTop;
        if (!this.$refs[this.swiperId]?.swiper) return;
        else if (this.scrolled > 400) {
          this.$refs[this.swiperId].swiper.autoplay.stop();
        }
      });
    }

    this.isClicked = false;
    let onClick = () => {
      this.onIframeClick();
      this.pushDataLayerEvent('clickContent', {
        ...this.currentBanner,
        position: this.positionForClickEvent,
      });
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', {
        creative: 'Banner',
        bannerId: this.currentBanner.bannerId,
        title: this.currentBanner.title,
        link: this.currentBanner.link,
      });
    };

    let eventListener = window.addEventListener('blur', function() {
      if (!this.isClicked && document.activeElement === document.getElementById('frame')) {
        this.isClicked = true;
        onClick();
        setTimeout(function() {
          window.focus();
        }, 0);
      }
      window.removeEventListener('blur', eventListener);
    });
  },
  created() {
    if (!this.slides || !this.config) {
      let serviceCall = null;
      if (this.swiperId === 'mainSliderFun') {
        serviceCall = Common.getFunBanners();
      } else {
        serviceCall = Common.getMainBanners();
      }

      let position = 0;
      serviceCall.then(res => {
        if (res.data && res.data.Data) {
          const dynamicBanners = res.data.Data.dynamicBanners.map(banner => ({
            ...banner,
            image: banner.bannerImage,
            isDynamicBanner: true,
            bannerId: banner.id,
          }));
          const combinedBanners = [...res.data.Data.banners, ...dynamicBanners];

          combinedBanners.sort((a, b) => a.order - b.order);

          this.contents = combinedBanners.map(({ image: imageURL, ...rest }) => ({
            imageURL,
            position: ++position,
            ...rest,
          }));
          this.initContentSlider();
          this.options = res.data.Data.config;
          this.setSwiperOptions(this.options);
        }
      });
    } else {
      this.contents = this.slides.map(
        ({ contentId: bannerId, largeImageURL: imageURL, ...rest }) => ({
          bannerId,
          imageURL,
          ...rest,
        }),
      );
      this.options = this.config;
      this.setSwiperOptions(this.options);
    }
  },
  data() {
    return {
      contents: null,
      options: null,
      autoPlay: true,
      dataLayer: [],
      promotionsForClick: [],
      promotionsForChanged: [],
      currentBanner: null,
      scrolled: 0,
      position: 0,
      positionForClickEvent: 0,
      positionForSelectPromotion: 0,
      sliderOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        resistance: true,
        resistanceRatio: 0.7,
        watchOverflow: true,
        autoplay: { delay: 5000, disableOnInteraction: true },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      isChangedSlider: false,
      viewed_promotions: [],
      webParentBanner: {
        width: 1024,
        height: 573,
      },
      appParentBanner: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    media() {
      return {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.slider-container {
  position: relative;
  .bg-image {
    background-size: cover;
    background-position: center center;
  }
  .swiper-pagination {
    line-height: 8px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
  }
  /deep/ .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 0.6;
    border: 1px solid #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &-active {
      opacity: 1;
    }
  }
  .player-iframe {
    max-width: 100%;
    min-height: 228px;
    /* height: 20vh; */
    width: -webkit-fill-available;
    height: 100%;
    max-height: 573px;
  }
}
.banner-dynamic-content {
  position: absolute;
  z-index: 10;
}
</style>
