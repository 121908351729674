<template>
  <div
    v-if="weeklyMarathonInfo.length"
    :class="[isCollapsed ? 'detail-card collapsed' : 'detail-card shown']"
    :style="
      isHeader
        ? 'margin-bottom: 10px; width: 100%; background: linear-gradient(182deg, #ffa959 -23.76%, #ae4726 86.73%)'
        : 'padding: 0 5px'
    "
    class="detail-card"
  >
    <div>
      <div v-if="isHeader" class="title" @click="toggleMarathonCard">
        <div>Ödül Yağmuru Haftalık Performans</div>
        <VueIcon
          :class="[isCollapsed ? 'title collapsed' : 'title shown']"
          :width="icons.right.width"
          :height="icons.right.height"
          :iconName="icons.right.name"
          color="#ffffff"
        />
      </div>
      <div class="card-content" v-if="!isCollapsed">
        <div class="table-container">
          <table>
            <tr>
              <td class="column-header">Gün</td>
              <td class="column-header">Katılım Durumu</td>
            </tr>
            <tr v-for="(info, index) in weeklyMarathonInfo" :key="index">
              <td>
                {{ getDayOfWeek(info.date) }},<span> {{ getDayAndMonth(info.date) }}</span>
              </td>
              <td>
                <span class="status-icon" v-if="info.participationStatus !== null">
                  <VueIcon
                    :width="`${getIconInfo(info.participationStatus).width}`"
                    :height="`${getIconInfo(info.participationStatus).height}`"
                    :iconName="`${getIconInfo(info.participationStatus).iconName}`"
                  />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import Gamification from '@/services/Api/gamification';

export default {
  name: 'MarathonDetailCard',
  components: {
    VueIcon,
  },
  props: {
    isCollapsed: {
      type: Boolean,
    },
    isHeader: {
      type: Boolean,
    },
  },
  data() {
    return {
      weeklyMarathonInfo: [],
    };
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
  },
  created() {
    this.getWeeklyMarathonInfo();
  },
  methods: {
    getWeeklyMarathonInfo() {
      Gamification.getWeeklyMarathonInfo().then(res => {
        if (!res?.data?.Data) return;
        const allMarathonInfo = res.data.Data.weeklyMarathonInfo.filter(info => {
          const currentDate = new Date(info.date);
          return currentDate.getDay() !== 0 && currentDate.getDay() !== 6;
        });

        this.weeklyMarathonInfo = allMarathonInfo.slice(0, 5);
      });
    },
    toggleMarathonCard() {
      this.isCollapsed = !this.isCollapsed;
    },
    getIconInfo(status) {
      const icon = status ? this.icons.iconCircleCheck : this.icons.iconCircleCross;

      return {
        width: icon.width,
        height: icon.height,
        iconName: icon.name,
      };
    },
    getDayOfWeek(dateString) {
      const options = { weekday: 'long' };
      const dayOfWeek = new Date(dateString).toLocaleDateString('tr-TR', options);
      return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
    },
    getDayAndMonth(dateString) {
      const options = { day: 'numeric', month: 'long' };
      const dayAndMonth = new Date(dateString).toLocaleDateString('tr-TR', options);
      return dayAndMonth.charAt(0).toUpperCase() + dayAndMonth.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-card {
  width: 90%;
  display: flex;
  padding: 15px 20px;
  flex-direction: column;

  &.collapsed {
    height: 50px;
  }

  &.shown {
    height: auto;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &.collapsed {
      transform: rotateZ(0deg);
    }

    &.shown {
      transform: rotateZ(90deg);
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
    margin: 10px 0 20px 0;
  }

  table {
    width: 100%;
  }

  td {
    text-align: left;
    color: #fff;
    min-height: 40px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    span {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  tr {
    border-bottom: 1px solid #ffffff1a;
  }

  td:first-child {
    border-right: 1px solid #ffffff1a;
  }

  tr:first-child td {
    border-top: none;
  }

  td:last-child {
    display: flex;
    border-right: none;
    justify-content: center;
  }

  .column-header {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
  }

  .status-icon {
    display: flex;
  }
}
</style>
